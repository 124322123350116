import Swiper, { Navigation } from 'swiper';

export class Slides {
    constructor() {
        this.speed = 700;
    }

    init() {
        const self = this;

        if (document.querySelectorAll('.swiper-container').length > 0) {
            Array.from(document.querySelectorAll('.swiper-container')).forEach(function (s) {
                var slideshow = new Swiper(s, {
                    modules: [Navigation],
                    pagination: {

                    },
                    loop: false,
                    speed: 800,
                    grabCursor: true,
                    slidesPerView: 3,
                    spaceBetween: 60,
                    navigation: {
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-prev",
                    },
                    breakpoints: {
                        0: {
                            slidesPerView: 1.5,
                            spaceBetween: 60
                        },
                        640: {
                            slidesPerView: 2.5,
                            spaceBetween: 40
                        },
                        1100: {
                            slidesPerView: 2.5,
                            spaceBetween: 60
                        },
                    },

                    autoplay: {

                    },
                    on: {
                        init: function () {

                        },
                        slideChangeTransitionStart: function () {

                        }
                    },
                });
            });
        }
    }
}