export class BurgerNavi {
    constructor(base) {
        this.base = base;
        this.menuButton = document.getElementById('menuButton');
        this.mainNavWrapper = document.getElementById('mainNavWrapper');
    }

    init() {
        const self = this;
        this.menuButton.addEventListener('click', this.openNav.bind(this));
        /*Array.from(this.mainNavWrapper.querySelectorAll('li')).forEach(function (ne) {
            ne.addEventListener('click', function (e) {
                self.menuButton.classList.remove('is-active');
                self.mainNavWrapper.classList.add('closed');
            });
        });*/

        Array.from(this.mainNavWrapper.querySelectorAll('li')).forEach(function (ne) {
            if (ne.querySelectorAll('.sub-menu').length > 0) {
                ne.addEventListener('click', function (e) {
                    let h = 0;
                    Array.from(this.querySelectorAll('li')).forEach(function (l) {
                        h += l.getBoundingClientRect().height;
                    });
                    if(!ne.querySelector('.sub-menu').classList.contains('show')) {
                        ne.querySelector('.sub-menu').style.maxHeight = h + 80 + 'px';
                    } else {
                        ne.querySelector('.sub-menu').style.maxHeight = 0;
                    }
                    ne.querySelector('.sub-menu').classList.toggle('show');
                    ne.classList.toggle('show');
                    //e.preventDefault();
                });
            }
        });
    }

    openNav(evt) {
        if (this.mainNavWrapper.classList.contains('hide-nav')) {
            this.mainNavWrapper.classList.remove('hide-nav');
        }
        if (!this.menuButton.classList.contains('is-active')) {
            this.menuButton.classList.add('is-active');
            this.mainNavWrapper.classList.remove('closed');
            this.hideOnClickOutside('header#masthead');
        } else {
            this.menuButton.classList.remove('is-active');
            this.mainNavWrapper.classList.add('closed');
        }
        if (evt != null) {
            evt.preventDefault();
        }
    }
    hideOnClickOutside(element) {
        let self = this;

        const outsideClickListener = event => {
            if (event.target.closest(element) === null) {
                if (!self.mainNavWrapper.classList.contains('closed')) {
                    self.openNav(null);
                    removeClickListener();
                }
            }
        };

        const removeClickListener = () => {
            document.removeEventListener('click', outsideClickListener);
        };

        document.addEventListener('click', outsideClickListener);
    }
}

