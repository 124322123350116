import Shuffle from 'shufflejs';
import { gsap } from "gsap";

export class SortItems {
    constructor(sorthash) {
        this.sorthash = window.location.hash.substring(1);
        this.shuffle = null;
        this.itemSelector = 'article.preview';
        this.container = document.querySelector('.shuffle-container');
        this.containerwrapper = document.querySelector('.shuffle-container-wrapper');
        this.resetBtn = document.getElementsByClassName('reset-filter');
        this.articleValArray = [];
    }
    init() {
        //var Shuffle = window.Shuffle;
        console.log(window.location.hash.substring(1));
        var self = this;

        this.targetgroup = Array.from(document.querySelectorAll('.filterlevel-1 .kdb-button'));
        this.category = Array.from(document.querySelectorAll('.filterlevel-2 .filter-btn'));
        this.theme = Array.from(document.querySelectorAll('.filterlevel-3 .filter-btn'));

        Array.from(document.querySelectorAll('.shuffle-container article.preview')).forEach(function (art, index) {
            self.articleValArray[index] = [];
            self.articleValArray[index][0] = art.getAttribute('data-targetgroup');
            self.articleValArray[index][1] = art.getAttribute('data-format');
            self.articleValArray[index][2] = art.getAttribute('data-theme');
            self.articleValArray[index][3] = art.getAttribute('data-btncategory');
        });

        this.shuffle = new Shuffle(self.container, {
            itemSelector: self.itemSelector,
            speed: 0
        });

        this.filters = {
            targetgroup: [],
            category: [],
            theme: [],
            btncategory: []
        };

        this._bindEventListeners();
        Array.from(this.resetBtn).forEach(function (btn) {
            btn.addEventListener('click', self.resetFilters.bind(self), false);
        });

        document.querySelector('.show-filter-btn').addEventListener('click', function (btn) {
            self.toggleFilter();
        });

        this.preSelectedBtnGroup();
    }
    //
    preSelectedBtnGroup() {
        var self = this;
        this.resetFilters();

        this.checkIfArticles();

        this.filters.btncategory = this._getCurrentBtnCategoryFilters();

        if (self.hasActiveFilters()) {
            self.shuffle.filter(self.itemPassesBtnFilters.bind(self));
        } else {
            self.shuffle.filter(Shuffle.ALL_ITEMS);
        }

        this.fadeIn();
    }
    //
    toggleFilter() {
        if (!document.querySelector('.filter .content-nav').classList.contains('show')) {
            document.querySelector('.filter .content-nav').classList.add('show');
            document.querySelector('.show-filter-btn i').classList.add('down');
            gsap.to(document.querySelector('.filter .content-nav'), { height: 'auto', duration: 0.2 });
        } else {
            document.querySelector('.filter .content-nav').classList.remove('show');
            document.querySelector('.show-filter-btn i').classList.remove('down');
            gsap.to(document.querySelector('.filter .content-nav'), { height: 0, duration: 0.2 });
        }
    }

    _bindEventListeners() {
        this._onTargetGroupChange = this._handleTargetGroupChange.bind(this);
        this._onCategoryChange = this._handleCategoryChange.bind(this);
        this._onThemeChange = this._handleThemeChange.bind(this);

        this.targetgroup.forEach(function (el) {
            el.addEventListener('click', this._onTargetGroupChange);
        }, this);

        this.category.forEach(function (el) {
            el.addEventListener('click', this._onCategoryChange);
        }, this);

        this.theme.forEach(function (el) {
            el.addEventListener('click', this._onThemeChange);
        }, this);
    }

    _getCurrentTargetGroupFilters() {
        return this.targetgroup.filter(function (button) {
            return button.classList.contains('selected');
        }).map(function (button) {
            return button.getAttribute('data-value');
        });
    }

    _getCurrentCategoryFilters() {
        return this.category.filter(function (button) {
            return button.classList.contains('selected');
        }).map(function (button) {
            return button.getAttribute('data-value');
        });
    }

    _getCurrentThemeFilters() {
        return this.theme.filter(function (button) {
            return button.classList.contains('selected');
        }).map(function (button) {
            return button.getAttribute('data-value');
        });
    }

    _getCurrentBtnCategoryFilters() {
        const search = ["%20", "ae", "oe", "ue", "ss", "Ae", "Oe", "Ue"];
        const replaceWith = [" ", "ä", "ö", "ü", "ß", "Ä", "Ö", "Ü"];
        let i = 0;
        let newhash = this.sorthash;
        for(i = 0; i < search.length; i++) {
            newhash = newhash.replaceAll(search[i], replaceWith[i]);
        }

        return newhash;
    }

    _handleTargetGroupChange(evt) {
        var self = this;
        var button = evt.currentTarget;
        this.resetFilters();
        if (button.classList.contains('selected')) {
            button.classList.remove('selected');
        } else {
            this.targetgroup.forEach(function (btn) {
                btn.classList.remove('selected');
            });

            button.classList.add('selected');
        }
        document.querySelector('.filterlevel-2').classList.add('opened');
        gsap.to(document.querySelector('.filterlevel-2'), { height: 'auto', duration: 0.2 });
        document.querySelector('.filterlevel-3').classList.add('opened');
        gsap.to(document.querySelector('.filterlevel-3'), { height: 'auto', duration: 0.2 });

        this.checkIfArticles();

        this.filters.targetgroup = this._getCurrentTargetGroupFilters();
        this.filters.category = this._getCurrentCategoryFilters();
        this.filters.theme = this._getCurrentThemeFilters();
        console.log(this.filters.targetgroup, this.filters.category);
        self.filter();

        this.fadeIn();
    }

    _handleCategoryChange(evt) {
        var self = this;
        var button = evt.currentTarget;

        if (button.classList.contains('selected')) {
            button.classList.remove('selected');
        } else {
            button.classList.add('selected');
        }

        this.checkIfArticles();

        this.filters.targetgroup = this._getCurrentTargetGroupFilters();
        this.filters.category = this._getCurrentCategoryFilters();
        this.filters.theme = this._getCurrentThemeFilters();
        self.filter();

        this.fadeIn();
    }

    _handleThemeChange(evt) {
        var self = this;
        var button = evt.currentTarget;

        if (button.classList.contains('selected')) {
            button.classList.remove('selected');
        } else {
            button.classList.add('selected');
        }

        this.checkIfArticles();

        this.filters.targetgroup = this._getCurrentTargetGroupFilters();
        this.filters.category = this._getCurrentCategoryFilters();
        this.filters.theme = this._getCurrentThemeFilters();

        self.filter();

        this.fadeIn();
    }

    checkIfArticles() {
        var self = this;
        var level1 = '';
        var i = 0;
        Array.from(document.querySelectorAll('.filterlevel-1 .kdb-button')).forEach(function (el1) {
            let category = el1.getAttribute('data-value');
            if (el1.classList.contains('selected')) {
                level1 = category;
            }
        });

        this.checkLevel2(level1);
        this.checkLevel3(level1);
    }
    checkLevel2(level1) {
        var self = this;
        Array.from(document.querySelectorAll('.filterlevel-2 .filter-btn')).forEach(function (el2) {
            let category = el2.getAttribute('data-value');
            el2.style.display = 'none';
            for (var i = 0; i < self.articleValArray.length; i++) {
                if (self.articleValArray[i][0].includes(level1)) {
                    if (self.articleValArray[i][1].includes(category)) {
                        el2.style.display = 'inline-block';
                    }
                }
            }
        });
    }
    checkLevel3(level1) {
        var self = this;
        var selection2 = false;
        Array.from(document.querySelectorAll('.filterlevel-3 .filter-btn')).forEach(function (el3) {
            let category = el3.getAttribute('data-value');
            el3.style.display = 'none';
            for (var i = 0; i < self.articleValArray.length; i++) {
                if (self.articleValArray[i][0].includes(level1)) {
                    if (self.articleValArray[i][2].includes(category)) {
                        Array.from(document.querySelectorAll('.filterlevel-2 .filter-btn')).forEach(function (el2) {
                            let category2 = el2.getAttribute('data-value');
                            if (el2.classList.contains('selected')) {
                                selection2 = true;
                                if (self.articleValArray[i][1].includes(category2)) {
                                    el3.style.display = 'inline-block';
                                }
                            }
                        });
                        if (selection2 == false) {
                            el3.style.display = 'inline-block';
                        }
                    }
                }
            }
        });
        Array.from(document.querySelectorAll('.filterlevel-3 .filter-btn')).forEach(function (el3) {
            if (el3.style.display == 'none') {
                el3.classList.remove('selected');
            }
        });
    }

    fadeIn() {
        var self = this;
        gsap.to(self.containerwrapper, { alpha: 0.4, duration: 0 });
        gsap.delayedCall(0.2, function () {
            gsap.to(self.containerwrapper, { alpha: 1, duration: 0.4 });
        });
    }

    filter() {
        var self = this;
        if (self.hasActiveFilters()) {
            self.shuffle.filter(self.itemPassesFilters.bind(self));
        } else {
            self.shuffle.filter(Shuffle.ALL_ITEMS);
        }
    }

    hasActiveFilters() {
        return Object.keys(this.filters).some(function (key) {
            return this.filters[key].length > 0;
        }, this);
    }

    itemPassesFilters(element) {
        var targetgroup = this.filters.targetgroup;
        var category = this.filters.category;
        var theme = this.filters.theme;
        var statusItem = element.getAttribute('data-targetgroup');
        statusItem = JSON.parse(statusItem);
        var categoryItem = element.getAttribute('data-format');
        categoryItem = JSON.parse(categoryItem);
        var themeItem = element.getAttribute('data-theme');
        themeItem = JSON.parse(themeItem);

        var i = 0;
        var isIn = 0;

        /*console.log('1 no', targetgroup.includes(statusItem));
        console.log('1 no', targetgroup);
        console.log('1 no', statusItem);
        if (targetgroup.length > 0 && !targetgroup.includes(statusItem)) {           
            return false;
        }

        if (category.length > 0 && !category.includes(categoryItem)) {
            return false;
        }*/

        if (targetgroup.length > 0) {
            for (i = 0; i < statusItem.length; i++) {
                if (targetgroup.includes(statusItem[i])) {
                    isIn = 1;
                }
            }
            if (isIn == 0) {
                return false;
            }
        }

        if (category.length > 0) {
            for (i = 0; i < categoryItem.length; i++) {
                if (category.includes(categoryItem[i])) {
                    isIn = 2;
                }
            }
            if (isIn < 2) {
                return false;
            }
        }

        if (theme.length > 0) {
            for (i = 0; i < themeItem.length; i++) {
                if (theme.includes(themeItem[i])) {
                    return true;
                }
            }
            return false;
        }

        return true;
    }

    itemPassesBtnFilters(element) {
        var targetgroup = this.filters.btncategory;
        var statusItem = element.getAttribute('data-btncategory');
        statusItem = JSON.parse(statusItem);

        var i = 0;
        var isIn = 0;

        if (targetgroup.length > 0) {
            for (i = 0; i < statusItem.length; i++) {
                if (targetgroup.includes(statusItem[i])) {
                    isIn = 1;
                }
            }
            if (isIn == 0) {
                return false;
            }
        }

        return true;
    }

    reStart() {
        var self = this;
        this.shuffle = new Shuffle(self.container, {
            itemSelector: self.itemSelector,
            speed: 0
        });
    }

    resetFilters(evt) {
        this.targetgroup.forEach(function (node) {
            node.classList.remove('selected');
        });
        this.category.forEach(function (node) {
            node.classList.remove('selected');
        });
        this.theme.forEach(function (node) {
            node.classList.remove('selected');
        });

        this.shuffle.filter(Shuffle.ALL_ITEMS);
        this.filters.targetgroup = this._getCurrentTargetGroupFilters();
        this.filters.category = this._getCurrentCategoryFilters();
        this.filters.theme = this._getCurrentThemeFilters();

        if (evt != undefined) {
            document.querySelector('.filterlevel-2').classList.remove('opened');
            gsap.to(document.querySelector('.filterlevel-2'), { height: 0, duration: 0.2 });
            document.querySelector('.filterlevel-3').classList.remove('opened');
            gsap.to(document.querySelector('.filterlevel-3'), { height: 0, duration: 0.2 });
        }

        this.fadeIn();

    }
}

