import { gsap } from "gsap";

export class ParallaxImg {
    constructor(breakpointMedium) {
        this.p2 = null;
        this.p3 = null;
        this.p4 = null;
        if (document.querySelector('.p-img-2') != null) {
            this.p2 = document.querySelector('.p-img-2');
        }
        if (document.querySelector('.p-img-3') != null) {
            this.p3 = document.querySelector('.p-img-3');
        }
        if (document.querySelector('.p-img-4') != null) {
            this.p4 = document.querySelector('.p-img-4');
        }
        this.distance = 0;
        this.breakpointMedium = breakpointMedium;
    }

    init() {
        this.parallaxFigures();
        window.addEventListener('resize', this.parallaxFigures.bind(this));
        window.addEventListener('scroll', this.parallaxFigures.bind(this));
    }
    setTopPosition() {
        if (this.p2 != null) {
            this.p2.style.top = Math.round(window.scrollY + document.querySelectorAll("section")[1].getBoundingClientRect().top - 40) + "px";
        }
        if (this.p3 != null) {
            this.p3.style.top = Math.round(window.scrollY + document.querySelectorAll("section")[1].getBoundingClientRect().top + 40) + "px";
        }
        if (this.p4 != null) {
            this.p4.style.top = Math.round(window.scrollY + document.querySelectorAll("section")[2].getBoundingClientRect().top - 20) + "px";
        }
    }
    parallaxFigures() {
        if (window.innerWidth >= this.breakpointMedium) {
            this.distance = window.scrollY;
            if (this.p2 != null) {
                this.p2.style.top = 0;
            }
            if (this.p3 != null) {
                this.p3.style.top = 0;
            }
            if (this.p4 != null) {
                this.p4.style.top = 0;
            }
            let diff = window.innerWidth / 9;
            if (window.innerWidth > 1440) {
                diff = 150;
            }
            if (this.p2 != null) {
                gsap.set(this.p2, { y: this.distance * 0.1 + window.scrollY + document.querySelectorAll("section")[1].getBoundingClientRect().top - diff / 2 });
            }
            if (this.p3 != null) {
                gsap.set(this.p3, { y: this.distance * 0.1 + window.scrollY + document.querySelectorAll("section")[1].getBoundingClientRect().top - diff / 2 + 40 });
            }
            if (this.p4 != null) {
                gsap.set(this.p4, { y: this.distance * 0.03 + window.scrollY + document.querySelectorAll("section")[2].getBoundingClientRect().top - diff * 2 });
            }
        }
    }
}