export class Filter {
    constructor() {
        this.filter = document.querySelector('.filter');
    }

    init() {
        const self = this;

        Array.from(this.filter.querySelectorAll('.kdb-button')).forEach(function (btn) {
            btn.addEventListener('click', function(evt) {
                self.resetAllBtns();
                this.classList.add('selected');

                self.showSection(this);
            });
        });
    }
    resetAllBtns() {
        Array.from(this.filter.querySelectorAll('.kdb-button')).forEach(function (btn) {
            btn.classList.remove('selected');
        });
    }
    showSection(btn) {
        Array.from(this.filter.querySelectorAll('.filter-el')).forEach(function (f) {
            f.classList.add('hidden');
            if(btn.getAttribute('data-filter') == f.getAttribute('data-filter')) {
                f.classList.remove('hidden');
            }
        });
    }
}