//import './app.scss';

import { BurgerNavi } from "./js/burgernavi.js";
import { BaseFunctions } from "./js/basefunctions.js";
import { ParallaxImg } from "./js/parallaximg.js";
import { Slides } from "./js/slides.js";
import { ImgSlides } from "./js/imgslides.js";
import { Filter } from "./js/filter.js";
import { SortItems } from "./js/sortitems.js";

const breakpointMedium = 640;


document.addEventListener("DOMContentLoaded", function(event) {
    //const sorthash = window.location.hash.substring(1);

    const burgernavi = new BurgerNavi();
    burgernavi.init();

    const basefunctions = new BaseFunctions(breakpointMedium);
    basefunctions.init();

    if(document.querySelectorAll('.swiper-container').length > 0) {
        const slides = new Slides();
        slides.init();
    }

    if(document.querySelectorAll('.img-swiper-container').length > 0) {
        const imgslides = new ImgSlides();
        imgslides.init();
    }

    if(document.querySelectorAll('.parallax-img').length > 0) {
        const parallaximg = new ParallaxImg(breakpointMedium);
        parallaximg.init();
    }

    if(document.querySelectorAll('.shuffle-filter').length > 0) {
        //const filter = new Filter();
        //filter.init();

        const sortitems = new SortItems();
        sortitems.init();
    }
    if(document.querySelectorAll('.simple-filter').length > 0) {
        const filter = new Filter();
        filter.init();
    }
});