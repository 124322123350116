import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin.js";
gsap.registerPlugin(ScrollToPlugin);

export class BaseFunctions {
    constructor(breakpointMedium) {
        this.breakpointMedium = breakpointMedium;
    }

    init() {
        this.scrollToAnchorInit();
        this.overImage();
        this.videoButton();
        this.newsletterButton();
        this.resize();
        window.addEventListener('resize', this.resize.bind(this));
        this.setPrintImageSizes();
        this.loadMoreBtn();
    }
    scrollToAnchorInit() {
        var self = this;
        //if (document.body.classList.contains('home')) {
        Array.from(document.querySelectorAll('a[href*="#"]')).forEach(function (anchor) {
            if (anchor.getAttribute('href') !== "#newsletter") {
                anchor.addEventListener('click', function (e) {
                    if (document.body.classList.contains('home')) {
                        self.scrollToAnchor(e, anchor.getAttribute('href'));
                        e.preventDefault();
                    }
                });
            }
        });
        //}
        if (document.querySelectorAll('.shuffle-filter').length == 0) {
            if (window.location.hash) {
                if (window.location.hash !== "#newsletter") {
                    window.addEventListener('load', this.scrollToAnchor.bind(this));
                } else {
                    window.location.hash = "";
                }
            }
        }
    }
    scrollToAnchor(evt, anchor) {
        if (anchor == undefined) {
            anchor = window.location.hash;
        }
        var self = this;
        let a = anchor.split('#')[1];
        if (a != null && a != undefined) {
            let p = document.getElementById(a).getBoundingClientRect().top + window.pageYOffset - 30;
            gsap.to(window, { duration: 1, scrollTo: p, ease: "power3.out" });
        }
    }
    overImage() {
        var self = this;
        Array.from(document.querySelectorAll('.over-img')).forEach(function (img) {
            img.addEventListener('mouseover', function (e) {
                img.querySelector('img:last-of-type').classList.add('show');
            });
            img.addEventListener('mouseout', function (e) {
                img.querySelector('img:last-of-type').classList.remove('show');
            });
        });
    }
    videoButton() {
        var self = this;
        Array.from(document.querySelectorAll('figure.wp-block-video')).forEach(function (vb) {
            vb.querySelector('video').addEventListener('click', function (evt) {
                evt.preventDefault();
                this[this.paused ? 'play' : 'pause']();
            });
            vb.querySelector('video').addEventListener('ended', (event) => {
                var vSrc = vb.querySelector('video').getAttribute('src');
                vb.querySelector('video').setAttribute('src', vSrc);
            });
        });
    }
    newsletterButton() {
        if (document.querySelectorAll('.newsletter-btn').length > 0) {
            Array.from(document.querySelectorAll('.newsletter-btn')).forEach(function (nlb) {
                nlb.addEventListener('click', function () {
                    nlb.parentNode.querySelector('.newsletter-layer').classList.toggle('show');
                    document.querySelector('.banner').classList.toggle('show');
                    nlb.parentNode.querySelector('.newsletter-btn .arrow').classList.toggle('up');
                    if (nlb.parentNode.parentNode.classList.contains('banner')) {
                        //nlb.parentNode.parentNode.classList.toggle('large');
                        //nlb.parentNode.parentNode.querySelector('.newsletter-layer').style.width = document.querySelector('.banner .newsletter-btn').getBoundingClientRect().width - 5 + 'px';
                    }
                });
            });
            document.querySelector('.newsletter-close-btn').addEventListener('click', function () {
                document.querySelector('.newsletter-layer').classList.toggle('show');
                document.querySelector('.banner').classList.toggle('show');
            });
        }
    }
    resize() {
        if (document.querySelectorAll('.slider-nl-area').length > 0) {
            document.querySelector('.slider-nl-area').style.marginLeft = document.querySelector('.kdb-button.newsletter-btn').getBoundingClientRect().left + 'px';
        }
        /* if (document.querySelectorAll('.newsletter-btn').length > 0) {
             Array.from(document.querySelectorAll('.newsletter-btn')).forEach(function (nlb) {
                     if (nlb.parentNode.parentNode.classList.contains('banner')) {
                         nlb.parentNode.parentNode.querySelector('.newsletter-layer').style.width = document.querySelector('.banner .newsletter-btn').getBoundingClientRect().width - 5 + 'px';
                     }
             });
         }*/
    }
    setPrintImageSizes() {
        Array.from(document.querySelectorAll('.material img.print-large')).forEach(function (img) {
            if (img.parentNode.parentNode.parentNode.parentNode.classList.contains('grid-x')) {
                var gridNode = img.parentNode.parentNode.parentNode.parentNode;
                gridNode.classList.add('print-large-row');
            }
            if (img.parentNode.parentNode.classList.contains('grid-x')) {
                var gridNode = img.parentNode.parentNode;
                gridNode.classList.add('print-large-row');
            }
        });
    }

    loadMoreBtn() {
        const self = this;
        if (document.querySelectorAll('.load-more-section').length > 0) {
            Array.from(document.querySelectorAll('.load-more-section')).forEach(function (section) {
                const loadnum = 3;
                var loaded = 3;
                var nextbtn = section.querySelector('.n-next');
                //var prevbtn = section.querySelector('.n-prev');
                var i;
                if (loaded >= section.querySelectorAll('.teaser-item').length) {
                    nextbtn.classList.add('disabled');
                }
                /* if (loaded <= 0) {
                    prevbtn.classList.add('disabled');
                } */
                nextbtn.addEventListener('click', (evt) => {
                    console.log(loadnum, loaded);
                    for (i = loaded; i < loaded + loadnum; i++) {
                        if (i < section.querySelectorAll('.teaser-item').length) {
                            section.querySelectorAll('.teaser-item')[i].classList.remove('hidden');
                        }
                    }
                    loaded = loaded + loadnum;
                    if (loaded >= section.querySelectorAll('.teaser-item').length) {
                        nextbtn.classList.add('disabled');
                    }
                });
                /* prevbtn.addEventListener('click', (evt) => {
                    console.log(loadnum, loaded);
                    for (i = loaded; i > loaded - loadnum; i--) {
                        if (i >= 0) {
                            section.querySelectorAll('.teaser-item')[i].classList.remove('hidden');
                        }
                    }
                    loaded = loaded + loadnum;
                    if (loaded <= 0) {
                        prevbtn.classList.add('disabled');
                    }
                }); */
            });
        }
    }
}